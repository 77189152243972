import { useEffect, useRef } from 'react';

const useTxsLoaderBase = ({ exchangeRates, chainConfig }) => {
  const cursorValueRef = useRef();
  const nextPageExistsRef = useRef(true);
  const nextTxExistsRef = useRef(true);
  const txsBufferRef = useRef([]);
  const txsPointerRef = useRef(0);
  const lastLoadedPageRef = useRef(0);
  const totalPagesRef = useRef();

  const exchangeRatesRef = useRef(exchangeRates);

  useEffect(() => {
    exchangeRatesRef.current = exchangeRates;
  }, [exchangeRates]);

  const getNextTx = async (peekOnly = false) => {
    if (!nextTxExistsRef.current) {
      return undefined;
    }
    if (txsPointerRef.current >= txsBufferRef.current.length) {
      const updatedValues = await chainConfig.loadNextPage({
        lastLoadedPage: lastLoadedPageRef.current,
        totalPages: totalPagesRef.current,
        cursorValue: cursorValueRef.current,
        txsBuffer: txsBufferRef.current,
        nextPageExists: nextPageExistsRef.current,
        exchangeRates: exchangeRatesRef.current,
      });
      lastLoadedPageRef.current = updatedValues?.lastLoadedPage;
      totalPagesRef.current = updatedValues?.totalPages;
      cursorValueRef.current = updatedValues?.cursorValue;
      txsBufferRef.current = updatedValues?.txsBuffer;
      nextPageExistsRef.current = updatedValues?.nextPageExists;
    }
    if (txsPointerRef.current >= txsBufferRef.current.length) {
      nextTxExistsRef.current = false;
      return undefined;
    }
    const tx = txsBufferRef.current[txsPointerRef.current];
    if (!peekOnly) {
      txsPointerRef.current += 1;
    }
    return tx;
  };

  const hasNextTx = () => {
    return nextTxExistsRef.current;
  };

  const getLastLoadedPage = () => {
    return lastLoadedPageRef.current;
  };

  const getTotalPages = () => {
    return totalPagesRef.current;
  };

  const reset = () => {
    nextTxExistsRef.current = true;
    nextPageExistsRef.current = true;
    txsPointerRef.current = 0;
    cursorValueRef.current = undefined;
    txsBufferRef.current = [];
    lastLoadedPageRef.current = 0;
    totalPagesRef.current = undefined;
  };

  return {
    getNextTx,
    hasNextTx,
    reset,
    getLastLoadedPage,
    getTotalPages,
  };
};

export default useTxsLoaderBase;
