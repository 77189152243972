import { clsx } from 'clsx';
import styles from './aml-email-generator.module.scss';
import globalStyles from '../customer-details/style.module.scss';
import Header from '../../components/header/Header';
import routes from '../../routes/routes';
import CustomerDetailsBox from '../customer-details/CustomerDetailsBox';
import { ReactComponent as WritingIcon } from '../../assets/images/svg/writing-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/svg/plus-icon.svg';
import { ReactComponent as ResetIcon } from '../../assets/images/svg/refresh.svg';
import { useContext, useEffect, useState } from 'react';
import Select from '../../components/select/Select';
import MainButton from '../../components/buttons/MainButton';
import CexModal from '../../components/modals/aml-email-generator-modals/CexModal';
import { UserContext } from '../../context/user/UserContext';
import { SOURCE_TYPE_KEYS, CUSTOMER_TYPE_KEYS } from '../../utils/email-generator';
import UniversalDetailRow from '../../components/modals/aml-email-generator-modals/detail-rows/UniversalDetailsRow';
import { generateEmail } from '../../services/email-generator/email-generator';

const AmlEmailGenerator = () => {
  const customerTypeOptions = [
    { label: 'Individual', key: CUSTOMER_TYPE_KEYS.individual },
    { label: 'Company', key: CUSTOMER_TYPE_KEYS.company }
  ];

  const sourceTypeOptions = [
    { label: 'Bought on CEX', key: SOURCE_TYPE_KEYS.boughtOnCex },
    { label: 'Profits from trading on CEX', key: SOURCE_TYPE_KEYS.profitsFromCexTrading },
    { label: 'Mining', key: SOURCE_TYPE_KEYS.mining },
    { label: 'Staking', key: SOURCE_TYPE_KEYS.staking },
    { label: 'Airdrop', key: SOURCE_TYPE_KEYS.airdrop },
    { label: 'Swap', key: SOURCE_TYPE_KEYS.swap },
    { label: 'Lending and liquidity pools', key: SOURCE_TYPE_KEYS.lendingAndLiquidity },
    { label: 'ICO or token sale', key: SOURCE_TYPE_KEYS.ico },
    { label: 'Income invoiced in cryptocurrency', key: SOURCE_TYPE_KEYS.income },
  ];

  const { providersInfo } = useContext(UserContext);

  const [cexList, setCexList] = useState([]);

  const [customerType, setCustomerType] = useState(customerTypeOptions[0].label);
  const [customerTypeKey, setCustomerTypeKey] = useState(customerTypeOptions[0].key);
  const [sourceType, setSourceType] = useState(sourceTypeOptions[0].label);
  const [sourceTypeKey, setSourceTypeKey] = useState(sourceTypeOptions[0].key);
  const [addedSources, setAddedSources] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailText, setEmailText] = useState('');

  useEffect(() => {
    if (providersInfo) {
      const cexNames = providersInfo
        .filter(provider => String(provider?.account).startsWith('exchange'))
        .map(provider => provider.name);
      setCexList(cexNames);
    }
  }, [providersInfo]);

  const handleCustomerTypeChange = (option) => {
    setCustomerType(option.label);
    setCustomerTypeKey(option.key);
  };

  const handleSourceTypeChange = (option) => {
    setSourceType(option.label);
    setSourceTypeKey(option.key);
  };

  const handleAddSource = () => {
    if ([SOURCE_TYPE_KEYS.boughtOnCex, SOURCE_TYPE_KEYS.profitsFromCexTrading].includes(sourceTypeKey)) {
      setIsModalOpen(true);
    } else {
      // No modal needed for other types, as they do not need any additional information
      addSource({
        type: sourceTypeKey,
        description: sourceType,
        addedAt: (new Date()).getTime(),
      });
    }
  };

  const handleAddSourceModalClose = () => {
    setIsModalOpen(false);
  };

  const addSource = (sourceDetails) => {
    setAddedSources(prevSources => [...prevSources, sourceDetails]);
  };

  const handleDelete = (index) => {
    setAddedSources(prevSources => [...prevSources.slice(0, index), ...prevSources.slice(index + 1)]);
  };

  const handleResetForm = () => {
    setAddedSources([]);
    setEmailText('');
    setSourceType(sourceTypeOptions[0].label);
    setSourceTypeKey(sourceTypeOptions[0].key);
  };

  const handleEmailGeneration = () => {
    const text = generateEmail(customerTypeKey, addedSources);
    setEmailText(text);
  };

  useEffect(() => {
    handleEmailGeneration();
  }, [addedSources, customerType]);

  return <div className='page-content-container'>
    {isModalOpen && [SOURCE_TYPE_KEYS.boughtOnCex, SOURCE_TYPE_KEYS.profitsFromCexTrading].includes(sourceTypeKey) &&
      <CexModal
        sourceType={sourceType}
        sourceTypeKey={sourceTypeKey}
        listOfCex={cexList}
        handleClose={handleAddSourceModalClose}
        addSource={addSource}
      />
    }
    <div className={clsx(styles.container, globalStyles.customers)}>
      <Header
        headerTitle='Knowledge hub / AML email generator'
        withBackButton
        backRoute={routes.home.knowledgeHub.dashboard}
      />

      <CustomerDetailsBox
        title='Define sources'
        icon={<WritingIcon/>}
      >
        <div className={styles.formContainer}>

          <div className={styles.inputWithLabelContainer}>
            <div className={styles.label}>
              Customer type:
            </div>
            <Select
              options={customerTypeOptions}
              name='customerType'
              placeholder='Customer type'
              value={customerType}
              onOptionClick={handleCustomerTypeChange}
            />
          </div>

          <hr/>

          <div className={styles.formControlsContainer}>
            <div className={styles.inputWithLabelContainer}>
              <Select
                options={sourceTypeOptions}
                name='sourceType'
                placeholder='Source type'
                value={sourceType}
                onOptionClick={handleSourceTypeChange}
                selectClassName={styles.wideSelect}
              />

              <MainButton
                onClick={handleAddSource}
                variant='button-gray'
                label='ADD SOURCE'
                icon={<PlusIcon/>}
                extraClasses={styles.formControlsButton}
              />
            </div>

            <MainButton
              onClick={handleResetForm}
              variant='button-error'
              label='RESET FORM'
              icon={<ResetIcon/>}
              extraClasses={styles.formControlsButton}
            />
          </div>

          {addedSources.length > 0 && <div className={styles.sourcesTable}>
            {addedSources.map((sourceDetails, index) => {
              return <div key={`source-${sourceDetails.type}-${sourceDetails.addedAt}`}>
                <UniversalDetailRow
                  sourceTypeDescription={sourceDetails.description}
                  columns={[sourceDetails?.cexName].filter(data => data)}
                  handleDelete={() => handleDelete(index)}
                />
              </div>;
            })}
          </div>}

          {addedSources.length > 0 && <hr />}

          {addedSources.length > 0 && <div className={styles.emailText}>
            <div className={styles.emailHeading}>Generated e-mail text</div>
            {emailText}
          </div>}

        </div>
      </CustomerDetailsBox>
    </div>
  </div>;
};

export default AmlEmailGenerator;
