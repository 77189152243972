import useTxsLoaderBase from './txsLoaderBase';
import { getLtcChainConfig } from '../../chain-config/ltc-chain-config';

const useLtcTxsLoader = ({ address, exchangeRates, currency, riskDetail, accountId, highRiskOnly = false }) => {
  const ltcChainConfig = getLtcChainConfig({
    address,
    accountId,
    currency,
    highRiskOnly,
    riskDetail,
  });
  
  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: ltcChainConfig,
  });

  return { getNextTx, hasNextTx, reset, getLastLoadedPage, getTotalPages };
};

export default useLtcTxsLoader;
