import { unique } from '../../../utils/common';

const USDT_CONTRACT_TYPE = 31;
const STAKE_TRON_CONTRACT_TYPE = 54;
const DELEGATE_ENERGY_CONTRACT_TYPE = 57;
const RECLAIM_ENERGY_CONTRACT_TYPE = 58;
const KNOWN_CONTRACT_TYPES = [
  DELEGATE_ENERGY_CONTRACT_TYPE,
  RECLAIM_ENERGY_CONTRACT_TYPE,
  STAKE_TRON_CONTRACT_TYPE,
  USDT_CONTRACT_TYPE
];
const STAKE_TRON_TYPE = 'stake';
const DELEGATE_RESOURCES_TYPE = 'delegate resources';
const RECLAIM_RESOURCES_TYPE = 'reclaim resources';
const USDT_CONTRACT_ADDRESS = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';

export const transformToAccountTx = (tronscanTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const decimals = tronscanTx?.tokenInfo?.tokenDecimal ?? 6;
  let value = tronscanTx?.contractData?.amount ?? 0;
  let valueDecimalized = value / Math.pow(10, decimals);
  let convertedValue = valueDecimalized * (exchangeRates?.['trx'] ?? 0);
  const tokenId = tronscanTx?.tokenInfo?.tokenId ?? 'TRON:';
  let currency = (tokenId === '_' ? 'trx' : `TRON:${String(tokenId)}`).toUpperCase();
  let currencyName = tronscanTx?.tokenInfo?.tokenName ?? '?';
  let currencySymbol = (tronscanTx?.tokenInfo?.tokenAbbr ?? '?').toUpperCase();
  let ownerAddress = tronscanTx.ownerAddress;
  let toAddress = tronscanTx.toAddress;
  let ownerTag = tronscanTx?.ownerAddressTag;
  let toTag = tronscanTx?.toAddressTag;

  let type = 'transfer';
  if (value > 0) {
    if (originatorAddress === tronscanTx.ownerAddress && originatorAddress !== tronscanTx.toAddress) {
      type = 'crypto withdrawal';
    } else if (originatorAddress !== tronscanTx.ownerAddress && originatorAddress === tronscanTx.toAddress) {
      type = 'crypto deposit';
    }
  }

  if (tronscanTx?.contractData?.amount === undefined) {
    // For BANDWIDTH and ENERGY delegations and USDT
    if (KNOWN_CONTRACT_TYPES.includes(tronscanTx?.contractType)) {
      valueDecimalized = 1;
      value = 1000000;
      currency = `TRON:${tronscanTx.contractData?.resource}`;
      currencyName = String(tronscanTx.contractData?.resource);
      currencySymbol = String(tronscanTx.contractData?.resource).toUpperCase();
      if (tronscanTx.contractType === RECLAIM_ENERGY_CONTRACT_TYPE) {
        type = RECLAIM_RESOURCES_TYPE;
        [ownerAddress, toAddress] = [toAddress, ownerAddress];
        [ownerTag, toTag] = [toTag, ownerTag];
      } else if (tronscanTx.contractType === DELEGATE_ENERGY_CONTRACT_TYPE) {
        type = DELEGATE_RESOURCES_TYPE;
      } else if (tronscanTx.contractType === STAKE_TRON_CONTRACT_TYPE) {
        type = STAKE_TRON_TYPE;
        currency = 'TRX';
        currencyName = 'TRX';
        currencySymbol = 'TRX';
        toAddress = ownerAddress;
        toTag = ownerTag;
        value = (tronscanTx.contractData?.frozen_balance ?? 0);
        valueDecimalized = value / 1000000;
        convertedValue = valueDecimalized * exchangeRates['trx'];
      } else if (tronscanTx?.trigger_info && String(tronscanTx?.trigger_info?.method).includes('transfer')) {
        if (tronscanTx.trigger_info.contract_address === USDT_CONTRACT_ADDRESS) {
          value = Number(tronscanTx.trigger_info?.parameter?._value ?? 0);
          valueDecimalized = value / 1000000; // Appropriate for USDT
          convertedValue = valueDecimalized * (exchangeRates?.['usdt'] ?? 0);
          toAddress = tronscanTx.trigger_info?.parameter?._to;
          currency = `TRON:${USDT_CONTRACT_ADDRESS}`;
          currencyName = 'USDT';
          currencySymbol = 'USDT';
        }
      }
    }
  }

  // Clear unwanted contract interactions
  if (currencyName === String(undefined)) {
    value = 0;
    valueDecimalized = 0;
  }

  const formatCurrencyValue = (value, decimals, currency, name, symbol) => {
    return {
      value: value / Math.pow(10, decimals),
      currency: currency,
      currency_name: name,
      currency_symbol: symbol
    };
  };

  const input = {
    address: ownerAddress,
    currency: currency,
    currency_name: currencyName,
    currency_symbol: currencySymbol,
    value: valueDecimalized,
    owned: ownerAddress === originatorAddress,
    fee: false,
    categories: [],
  };
  if (ownerTag) {
    input.category = ownerTag;
    input.categories = [ownerTag];
  }

  const output = {
    address: toAddress,
    currency: currency,
    currency_name: currencyName,
    currency_symbol: currencySymbol,
    value: valueDecimalized,
    owned: toAddress === originatorAddress,
    fee: false,
    categories: [],
  };
  if (toTag) {
    output.category = toTag;
    output.categories = [toTag];
  }

  const feeInfo = formatCurrencyValue(
    tronscanTx?.cost?.net_fee ?? 0,
    6,
    'trx',
    'trx',
    'trx',
  );

  if ([`TRON:${USDT_CONTRACT_ADDRESS}`.toLowerCase(), 'trx'].includes(currency.toLowerCase())) {
    input['converted_value'] = convertedValue;
    input['converted_currency'] = fiatCurrency;
    output['converted_value'] = convertedValue;
    output['converted_currency'] = fiatCurrency;
    feeInfo['converted_value'] = feeInfo.value * (exchangeRates?.[currency.toLowerCase() === 'trx' ? 'trx' : 'usdt'] ?? 0);
    feeInfo['converted_currency'] = fiatCurrency;
  }

  const amount = formatCurrencyValue(
    value,
    decimals,
    currency,
    currencyName,
    currencySymbol,
  );
  const transformedTx = {
    transaction_id: tronscanTx.hash,
    account_id: accountId,
    type: type,
    amount: [amount],
    total: [amount],
    fee: feeInfo,
    created_at: new Date(tronscanTx.timestamp).toISOString(),
    inputs: [input].filter(input => input.value > 0),
    outputs: [output].filter(output => output.value > 0),
    categories: unique([ownerTag, toTag].filter(tag => tag)),
    hideValue: [DELEGATE_RESOURCES_TYPE, RECLAIM_RESOURCES_TYPE].includes(type),
  };
  return transformedTx;
};
