import useTxsLoaderBase from './txsLoaderBase';
import useExternalApiKeys from '../externalApiKeys';
import { getTonChainConfig } from '../../chain-config/ton-chain-config';

const useTonTxsLoader = ({ address, exchangeRates, currency, riskDetail, accountId, highRiskOnly = false }) => {
  const { externalApiKeys } = useExternalApiKeys();

  const tonChainConfig = getTonChainConfig({
    address,
    accountId,
    currency,
    highRiskOnly,
    riskDetail,
    externalApiKey: externalApiKeys.toncenter,
  });


  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: tonChainConfig,
  });

  return { getNextTx, hasNextTx, reset, getLastLoadedPage, getTotalPages };
};

export default useTonTxsLoader;
