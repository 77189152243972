import styles from './aml-email-generator-modal.module.scss';
import MainModal from 'components/modals/MainModal';
import Select from '../../select/Select';
import { useEffect, useState } from 'react';
import { valueExists } from '../../../utils/common';
import MainButton from '../../buttons/MainButton';
import { ReactComponent as BoltGreen } from '../../../assets/images/svg/boltGreenIcon.svg';

const CexModal = ({ sourceType, sourceTypeKey, listOfCex = [], addSource, handleClose }) => {

  const [modalError, setModalError] = useState('');
  const cexOptions = listOfCex.map(cexName => ({ label: cexName }));

  const [cexName, setCexName] = useState(cexOptions[0].label);

  useEffect(() => {
    if (cexOptions.length === 0) {
      setModalError('No CEX names available to choose from');
    }
  }, [listOfCex]);

  const handleSubmit = () => {
    addSource({
      type: sourceTypeKey,
      description: sourceType,
      cexName,
      addedAt: (new Date()).getTime(),
    });
    handleClose();
  };

  return (
    <MainModal handleClose={handleClose}>
      <div className={styles.outerContainer}>
        <div className={styles.innerContainer}>

          <div className={styles.modalTitleContainer}>
            <div className={styles.modalTitle}>
              Add source
            </div>
            <div className={styles.sourceType}>
              Source type: <strong>{sourceType}</strong>
            </div>
          </div>

          {valueExists(modalError) && <div className={styles.modalError}>
            {modalError}
          </div>}

          <div className={styles.inputWithLabelContainer}>
            <div className={styles.label}>
              CEX:
            </div>
            <Select
              options={cexOptions}
              name='sourceCexName'
              placeholder='Select CEX'
              value={cexName}
              onOptionClick={option => setCexName(option.label)}
            />
          </div>

          <MainButton
            variant='button-purple'
            label='ADD SOURCE'
            icon={<BoltGreen />}
            onClick={handleSubmit}
          />

        </div>
      </div>
    </MainModal>
  );
};

export default CexModal;
