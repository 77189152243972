import axios from 'axios';
import { transformToAccountTx } from '../tx-transformers/tatum-doge';
import { determineTxAcceptance } from '../../../utils/account-tx-parser';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { isHighRisk } from '../../../utils/riskLevels';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';
import { valueExists } from '../../../utils/common';

export const getDogeChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, riskDetail }) => {
  const pageSize = 50;

  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;
      if (valueExists(accountsFilterParam) && !accountsFilterParam.split(',').includes(accountId)) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
      if (valueExists(digitalAssetsFilterParam) && !digitalAssetsFilterParam.split(',').includes('DOGE')) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
    }

    if (updatedValues.cursorValue === undefined) {
      updatedValues.cursorValue = 0;
    }
    const url = `https://api.tatum.io/v3/dogecoin/transaction/address/${address}`;
    const headers = { 'x-api-key': externalApiKey };
    const response = await axios.get(
      url,
      {
        params: {
          pageSize,
          offset: updatedValues.cursorValue,
        },
        headers,
      }
    );
    const newTatumTxs = response?.data;
    if (newTatumTxs === undefined || newTatumTxs.length === 0) {
      updatedValues.nextPageExists = false;
      return updatedValues;
    }

    updatedValues.cursorValue += pageSize;

    const accountTransactions = newTatumTxs
      .map(tatumTx => transformToAccountTx(tatumTx, address, accountId, exchangeRates, currency))
      .filter(accountTx => determineTxAcceptance(accountTx));
    let accountTransactionsWithRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, 'doge');
    if (highRiskOnly) {
      accountTransactionsWithRisk = accountTransactionsWithRisk.filter(tx => isHighRisk(tx?.risk ?? 0));
    }

    updatedValues.txsBuffer = [...txsBuffer, ...accountTransactionsWithRisk];
    return updatedValues;
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, getSupportedFilters };
};
