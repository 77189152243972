import {
  getSimpleTransactionFee, getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx
} from '../../../utils/account-tx-parser';

const BNB_CURRENCY_FACTOR = 1000000000000000000;

const getCreatedAt = (bscScanTx) => {
  const epochTime = bscScanTx.timeStamp;
  return new Date(epochTime * 1000);
};

const getTxFee = (bscScanTx) => {
  const gasUsed = Number(bscScanTx?.gasUsed ?? '0');
  const gasPrice = Number(bscScanTx?.gasPrice ?? '0');
  return (gasUsed * gasPrice) / BNB_CURRENCY_FACTOR;
};

export const transformToAccountTx = (bscScanTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'BNB';

  originatorAddress = originatorAddress.toLowerCase();

  let type = 'transfer';
  const fromLower = bscScanTx?.from.toLowerCase();
  const toLower = bscScanTx?.to.toLowerCase();
  if (bscScanTx?.value !== '0') {
    if (fromLower === originatorAddress && toLower !== originatorAddress) {
      type = 'crypto withdrawal';
    } else if (fromLower !== originatorAddress && toLower === originatorAddress) {
      type = 'crypto deposit';
    }
  }

  const accountTransaction = {
    created_at: getCreatedAt(bscScanTx),
    transaction_id: bscScanTx.hash,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  const amount = (bscScanTx?.value ?? 0) / BNB_CURRENCY_FACTOR;

  const inputMovement = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const outputMovement = {
    address: toLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: toLower === originatorAddress,
  };

  accountTransaction.inputs.push(inputMovement);
  accountTransaction.outputs.push(outputMovement);

  // Add fee
  const fee = getTxFee(bscScanTx);

  const feeInput = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};
