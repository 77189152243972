import { useContext } from 'react';
import { UserContext } from '../../../../context/user/UserContext';
import loadRiskTxsPage from '../../services/blockmate-risk-txs-service';
import useTxsLoaderBase from './txsLoaderBase';

const useBtcTxsLoader = ({ address, fiatCurrency, userJwt, riskDetail, exchangeRates }) => {
  const { getConfig } = useContext(UserContext);

  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: {
      loadNextPage: async ({
        cursorValue,
        txsBuffer,
        nextPageExists,
        lastLoadedPage,
        totalPages,
        exchangeRates,
        filters
      }) => {
        // TODO add filtering support
        const updatedValues = {
          cursorValue,
          txsBuffer,
          nextPageExists,
          lastLoadedPage,
          totalPages,
        };
        if (updatedValues.cursorValue === undefined) {
          updatedValues.cursorValue = 0;
        }
        const { transactions, page: loadedPage, totalPages: totalPagesToLoad } = await loadRiskTxsPage({
          chain: 'btc',
          page: updatedValues.cursorValue + 1,
          ownerAddr: address,
          exchangeRates: exchangeRates,
          fiatCurrency,
          riskDetail,
          apiUrl: getConfig('api_url'),
          authKey: userJwt,
        });
        updatedValues.cursorValue += 1;
        updatedValues.txsBuffer = [...txsBuffer, ...transactions];
        updatedValues.lastLoadedPage = loadedPage;
        updatedValues.totalPages = totalPagesToLoad;
        if (transactions.length === 0) {
          updatedValues.nextPageExists = false;
        }
        return updatedValues;
      },
    },
  });

  return { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset };
};

export default useBtcTxsLoader;
