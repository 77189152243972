import useTxsLoaderBase from './txsLoaderBase';
import { getBchChainConfig } from '../../chain-config/bch-chain-config';

const useBchTxsLoader = ({ address, exchangeRates, currency, accountId, riskDetail, highRiskOnly = false }) => {
  const bchChainConfig = getBchChainConfig({
    address,
    accountId,
    currency,
    highRiskOnly,
    riskDetail,
  });

  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: bchChainConfig,
  });

  return { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset };
};

export default useBchTxsLoader;
