import {
  getSimpleTransactionFee, getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx
} from '../../../utils/account-tx-parser';

const getCreatedAt = (tatumTx) => {
  const epochTime = tatumTx?.time;
  return new Date(epochTime * 1000);
};

export const transformToAccountTx = (tatumTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'DOGE';

  // Beware, values in tatumTx are floats represented as strings
  let type = 'transfer';
  const totalOwnInValue = (tatumTx?.inputs ?? [])
    .filter(input => input?.coin?.address === originatorAddress)
    .reduce((acc, input) => acc + Number(input?.coin?.value ?? 0), 0);
  const totalOwnOutValue = (tatumTx?.outputs ?? [])
    .filter(output => output.address === originatorAddress)
    .reduce((acc, output) => acc + Number(output?.value ?? 0), 0);
  if (totalOwnInValue > totalOwnOutValue) {
    type = 'crypto withdrawal';
  } else if (totalOwnInValue < totalOwnOutValue) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(tatumTx),
    transaction_id: tatumTx.hash,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  (tatumTx?.inputs ?? []).forEach(input => {
    const inputMovement = {
      address: input?.coin?.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: Number(input?.coin?.value ?? 0), // Float represented as string in the response
      fee: false,
      owned: input?.coin?.address === originatorAddress,
    };
    accountTransaction.inputs.push(inputMovement);
  });

  (tatumTx?.outputs ?? []).forEach(output => {
    const outputMovement = {
      address: output?.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: Number(output?.value ?? 0), // Float represented as string in the response
      fee: false,
      owned: output?.address === originatorAddress,
    };
    accountTransaction.outputs.push(outputMovement);
  });

  // Add fee
  const fee = Number(tatumTx?.fee ?? 0);
  // It does not matter if this is not correct in this case, ignore fee movements anyway
  const feePayerAddress = tatumTx.inputs[0]?.coin?.address;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};
