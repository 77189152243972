import axios from 'axios';
import { transformToAccountTx } from '../tx-transformers/blockchaincom-bch';
import { determineTxAcceptance } from '../../../utils/account-tx-parser';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { isHighRisk } from '../../../utils/riskLevels';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';
import { valueExists } from '../../../utils/common';

export const getBchChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, riskDetail }) => {
  const pageSize = 50;

  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
      lastLoadedPage,
      totalPages,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;
      if (valueExists(accountsFilterParam) && !accountsFilterParam.includes(accountId)) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
      if (valueExists(digitalAssetsFilterParam) && !digitalAssetsFilterParam.includes('BCH')) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
    }

    if (cursorValue === undefined) {
      updatedValues.cursorValue = 0;
    }

    const txIdsUrl = `https://api.blockchain.info/haskoin-store/bch/address/${address}/transactions?limit=${pageSize}&offset=${updatedValues.cursorValue}`;
    const txIdsResponse = await axios.get(txIdsUrl);
    const txIds = (txIdsResponse?.data ?? []).map(txInfo => txInfo.txid);

    if (txIds.length === 0) {
      updatedValues.nextPageExists = false;
      return updatedValues;
    }

    const txsDetailsUrl = `https://api.blockchain.info/haskoin-store/bch/transactions?txids=${txIds.join(',')}`;
    const response = await axios.get(txsDetailsUrl);
    const newTransactions = (response?.data ?? []).map(tx => _maybeRemovePrefixes(tx));
    if (newTransactions === undefined || newTransactions.length === 0) {
      updatedValues.nextPageExists = false;
      return updatedValues;
    }

    updatedValues.cursorValue += newTransactions.length;

    const accountTransactions = newTransactions
      .map(bchTx => transformToAccountTx(bchTx, address, accountId, exchangeRates, currency))
      .filter(accountTx => determineTxAcceptance(accountTx));
    let accountTransactionsWithRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, 'bch');
    if (highRiskOnly) {
      accountTransactionsWithRisk = accountTransactionsWithRisk.filter(tx => isHighRisk(tx?.risk ?? 0));
    }

    updatedValues.txsBuffer = [...updatedValues.txsBuffer, ...accountTransactionsWithRisk];
    return updatedValues;
  };

  const _maybeRemovePrefixes = (tx) => {
    tx.inputs = tx.inputs.filter(input => input.address !== null);
    tx.inputs = tx.inputs.map(input => {
      if (input.address.includes(':')) {
        return {
          ...input,
          address: input.address.split(':')[1]
        };
      }
      return input;
    });
    tx.outputs = tx.outputs.filter(output => output.address !== null);
    tx.outputs = tx.outputs.map(output => {
      if (output.address.includes(':')) {
        return {
          ...output,
          address: output.address.split(':')[1]
        };
      }
      return output;
    });
    return tx;
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, getSupportedFilters };
};
