import styles from '../aml-email-generator-modal.module.scss';
import { ReactComponent as TrashIcon } from '../../../../assets/images/svg/trash-general.svg';

const UniversalDetailRow = ({ sourceTypeDescription, columns = [], handleDelete }) => {
  return <div className={styles.detailsRowContainer}>
    <div className={styles.deleteButton} onClick={() => handleDelete()}>
      <TrashIcon/>
    </div>
    <div className={styles.rowTitle}>
      {sourceTypeDescription}
    </div>
    {columns.map((columnData, index) => <div className={styles.rowData} key={`column-${columnData?.addedAt}-${index}`}>
      {columnData}
    </div>)}
  </div>;
};

export default UniversalDetailRow;
