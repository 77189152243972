import {
  getSimpleTransactionFee, getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx
} from '../../../utils/account-tx-parser';

const LTC_CURRENCY_FACTOR = 100000000;

const getCreatedAt = (litecoinspaceTx) => {
  const epochTime = litecoinspaceTx?.status?.block_time;
  return new Date(epochTime * 1000);
};

export const transformToAccountTx = (litecoinspaceTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'LTC';

  let type = 'transfer';
  const totalOwnInValue = (litecoinspaceTx?.vin ?? [])
    .filter(input => input.prevout.scriptpubkey_address === originatorAddress)
    .reduce((acc, input) => acc + input.prevout.value, 0);
  const totalOwnOutValue = (litecoinspaceTx?.vout ?? [])
    .filter(output => output.scriptpubkey_address === originatorAddress)
    .reduce((acc, input) => acc + input.value, 0);
  if (totalOwnInValue > totalOwnOutValue) {
    type = 'crypto withdrawal';
  } else if (totalOwnInValue < totalOwnOutValue) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(litecoinspaceTx),
    transaction_id: litecoinspaceTx.txid,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  (litecoinspaceTx?.vin ?? []).forEach(input => {
    const inputFrom = input.prevout.scriptpubkey_address;
    const inputMovement = {
      address: inputFrom,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: input.prevout.value / LTC_CURRENCY_FACTOR,
      fee: false,
      owned: inputFrom === originatorAddress,
    };
    accountTransaction.inputs.push(inputMovement);
  });

  (litecoinspaceTx?.vout ?? []).forEach(output => {
    const outputTo = output.scriptpubkey_address;
    const outputMovement = {
      address: outputTo,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: output.value / LTC_CURRENCY_FACTOR,
      fee: false,
      owned: outputTo === originatorAddress,
    };
    accountTransaction.outputs.push(outputMovement);
  });

  // Add fee
  const fee = litecoinspaceTx.fee / LTC_CURRENCY_FACTOR;
  // It does not matter if this is not correct in this case, ignore fee movements anyway
  const feePayerAddress = litecoinspaceTx.vin[0].prevout.scriptpubkey_address;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};
