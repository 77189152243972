import styles from './category-info.module.scss';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from '../../context/user/UserContext';
import TransactionsTable from './TransactionsTable';
import { clsx } from 'clsx';
import { ReactComponent as PlusIcon } from '../../assets/images/svg/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/svg/minus-icon.svg';
import toast from 'react-hot-toast';
import useBtcTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/btcTxsLoader';
import useEthTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/ethTxsLoader';
import useTronTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/tronTxsLoader';
import useSolTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/solTxsLoader';
import useBscTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/bscTxsLoader';
import useLtcTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/ltcTxsLoader';
import useBchTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/bchTxsLoader';
import useTonTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/tonTxsLoader';
import useDogeTxsLoader from '../../services/chain-data-loaders/hooks/quick-check/dogeTxsLoader';

const DISPLAY_PAGE_SIZE = 50; // Virtual page size for displaying

const CategoryInfo = ({ title, chain, address, userJwt, riskResult, exchangeRates, valueInfo, withBorder, expandable, markedCounterparties }) => {
  const { companyCurrency } = useContext(UserContext);

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastInvestigatedPage, setLastInvestigatedPage] = useState(0);
  const [totalPages, setTotalPages] = useState();

  const [isExpanded, setIsExpanded] = useState(false);
  const [canShowMoreTxs, setCanShowMoreTxs] = useState(false);

  const customAccountInfo = {
    address: address,
    description: `${chain.toUpperCase()}-01`,
  };

  const loaderInitData = {
    address,
    markedCounterparties,
    exchangeRates,
    currency: companyCurrency,
    riskDetail: riskResult?.risk_detail,
    userJwt,
  };
  const btcTxsLoader = useBtcTxsLoader(loaderInitData);
  const ethTxsLoader = useEthTxsLoader(loaderInitData);
  const tronTxsLoader = useTronTxsLoader(loaderInitData);
  const solTxsLoader = useSolTxsLoader(loaderInitData);
  const bscTxsLoader = useBscTxsLoader(loaderInitData);
  const ltcTxsLoader = useLtcTxsLoader(loaderInitData);
  const bchTxsLoader = useBchTxsLoader(loaderInitData);
  const tonTxsLoader = useTonTxsLoader(loaderInitData);
  const dogeTxsLoader = useDogeTxsLoader(loaderInitData);

  const txsLoader = useMemo(() => {
    if (chain === 'trx') {
      return tronTxsLoader;
    } else if (chain === 'sol') {
      return solTxsLoader;
    } else if (chain === 'bnb') {
      return bscTxsLoader;
    } else if (chain === 'ltc') {
      return ltcTxsLoader;
    } else if (chain === 'bch') {
      return bchTxsLoader;
    } else if (chain === 'ton') {
      return tonTxsLoader;
    } else if (chain === 'doge') {
      return dogeTxsLoader;
    } else if (chain === 'btc') {
      return btcTxsLoader;
    } else if (chain === 'eth') {
      return ethTxsLoader;
    }
    throw new Error(`No loader available for ${chain}`);
  }, [chain, address]);

  const handleToggleExpand = async () => {
    setIsExpanded(prevExpanded => {
      if (!prevExpanded) {
        showMoreTxs();
      }
      return !prevExpanded;
    });
  };

  const showMoreTxs = async () => {
    const newTxs = [];
    setIsLoading(true);
    const MAX_RETRIES = 4;
    let retriesRemaining = MAX_RETRIES;
    while (newTxs.length < DISPLAY_PAGE_SIZE && txsLoader.hasNextTx()) {
      try {
        const nextTx = await txsLoader.getNextTx();
        if (nextTx) {
          nextTx.account_id='this-account-id';
          newTxs.push(nextTx);
        }
        retriesRemaining = MAX_RETRIES;
      } catch (err) {
        retriesRemaining -= 1;
        if (retriesRemaining === 0) {
          toast.error(
            'The server is too busy at the moment. Please try again in 5 minutes. If the issue persists, please contact us.'
          );
          break;
        } else {
          console.error(err);
          toast.error(`${err.toString()}. Retrying now.`);
        }
      }
    }
    setTransactions(prevTransactions => [...prevTransactions, ...newTxs]);
    setCanShowMoreTxs(txsLoader.hasNextTx());
    setLastInvestigatedPage(txsLoader.getLastLoadedPage());
    setTotalPages(txsLoader.getTotalPages());
    setIsLoading(false);
  };

  return <div className={styles.outerContainer}>
    <div className={clsx(styles.container, withBorder && styles.entryBorder)}>
      <div className={styles.innerContainer}>
        <div className={styles.leftPart}>
          <div className={clsx(styles.expander, expandable && styles.clickable)}>
            {expandable && !isExpanded && <PlusIcon onClick={handleToggleExpand} />}
            {expandable && isExpanded && <MinusIcon onClick={handleToggleExpand} />}
            {!expandable && <div></div>}
          </div>
          <div className={styles.title}>
            {title}
          </div>
        </div>
        <div className={styles.rightPart}>
          <div className={valueInfo.isSafe ? styles.safe : styles.unsafe}>
            {valueInfo.text}
          </div>
        </div>
      </div>
      {isExpanded && <TransactionsTable
        transactions={transactions}
        isLoading={isLoading}
        lastLoadedPage={lastInvestigatedPage}
        totalPages={totalPages}
        accountInfoByAccountId={{ 'this-account-id': customAccountInfo }}
        currency={companyCurrency}
        canShowMoreTxs={canShowMoreTxs}
        showMoreTxs={showMoreTxs}
      />}
    </div>
  </div>;
};

export default CategoryInfo;
