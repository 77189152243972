import {
  getSimpleTransactionFee, getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx
} from '../../../utils/account-tx-parser';

const BCH_CURRENCY_FACTOR = 100000000;

const getCreatedAt = (blockchaincomTx) => {
  const epochTime = blockchaincomTx?.time;
  return new Date(epochTime * 1000);
};

export const transformToAccountTx = (blockchaincomTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'BCH';

  let type = 'transfer';
  const totalOwnInValue = (blockchaincomTx?.inputs ?? [])
    .filter(input => input.address === originatorAddress)
    .reduce((acc, input) => acc + input.value, 0);
  const totalOwnOutValue = (blockchaincomTx?.outputs ?? [])
    .filter(output => output.address === originatorAddress)
    .reduce((acc, output) => acc + output.value, 0);
  if (totalOwnInValue > totalOwnOutValue) {
    type = 'crypto withdrawal';
  } else if (totalOwnInValue < totalOwnOutValue) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(blockchaincomTx),
    transaction_id: blockchaincomTx.txid,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  (blockchaincomTx?.inputs ?? []).forEach(input => {
    const inputMovement = {
      address: input.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: input.value / BCH_CURRENCY_FACTOR,
      fee: false,
      owned: input.address === originatorAddress,
    };
    accountTransaction.inputs.push(inputMovement);
  });

  (blockchaincomTx?.outputs ?? []).forEach(output => {
    const outputMovement = {
      address: output.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: output.value / BCH_CURRENCY_FACTOR,
      fee: false,
      owned: output.address === originatorAddress,
    };
    accountTransaction.outputs.push(outputMovement);
  });

  // Add fee
  const fee = blockchaincomTx.fee / BCH_CURRENCY_FACTOR;
  // It does not matter if this is not correct in this case, ignore fee movements anyway
  const feePayerAddress = blockchaincomTx?.inputs?.[0]?.address ?? '';

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};
