import {
  getSimpleTransactionFee, getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx
} from '../../../utils/account-tx-parser';

const getCreatedAt = (transaction) => {
  const epochTime = transaction.utime;
  return new Date(epochTime * 1000);
};

export const transformToAccountTx = (toncenterTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  originatorAddress = originatorAddress.toLowerCase();
  const TON_CURRENCY_FACTOR = 1000000000;
  const currency = 'TON';

  let type = 'transfer';
  const fromLower = toncenterTx?.in_msg.source.toLowerCase();
  const toLower = toncenterTx?.in_msg.destination.toLowerCase();
  if (toncenterTx?.in_msg.value !== '0') {
    if (fromLower === originatorAddress && toLower !== originatorAddress) {
      type = 'crypto withdrawal';
    } else if (fromLower !== originatorAddress && toLower === originatorAddress) {
      type = 'crypto deposit';
    }
  }

  const accountTransaction = {
    created_at: getCreatedAt(toncenterTx),
    transaction_id: toncenterTx.transaction_id.hash,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  const amount = (Number(toncenterTx.in_msg.value ?? '0')) / TON_CURRENCY_FACTOR; // TODO is this value correct?

  const inputMovement = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const outputMovement = {
    address: toLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: toLower === originatorAddress,
  };

  accountTransaction.inputs.push(inputMovement);
  accountTransaction.outputs.push(outputMovement);

  // Add fee
  const fee = Number(toncenterTx?.fee);

  const feeInput = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};
