import styles from '../../pages/customer-details/style.module.scss';
import CustomerDetailsBox from 'pages/customer-details/CustomerDetailsBox';
import { useState } from 'react';
import { clsx } from 'clsx';
import ConsolidatedTransactionsTableRowConcise from '../../pages/customer-details/ConsolidatedTransactionsTableRowConcise';
import { ClipLoader } from 'react-spinners';
import ConsolidatedTransactionsFilters from '../filters/ConsolidatedTransactionsFilters';


const TransactionsTable = ({
  isLoading,
  transactions,
  lastLoadedPage,
  totalPages,
  accountInfoByAccountId,
  currency = 'USD',
  canShowMoreTxs = false,
  showMoreTxs,
  withFilters = true,
  accountsForFilter,
  setAccountsForFilter,
  txTypesForFilter,
  setTxTypesForFilter,
  digitalAssetsForFilter,
  setDigitalAssetsForFilter,
  txRisksForFilter,
  setTxRisksForFilter,
  priceForFilter,
  setPriceForFilter,
  unsupportedFilters,
  hiddenFilters,
  handleApplyFilters,
}) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState();

  return (
    <CustomerDetailsBox
      concise={true}
      disableBoxShadow={true}
      withHeader={false}
    >
      {withFilters && <ConsolidatedTransactionsFilters
        accounts={accountsForFilter}
        setAccounts={setAccountsForFilter}
        types={txTypesForFilter}
        setTypes={setTxTypesForFilter}
        assets={digitalAssetsForFilter}
        setAssets={setDigitalAssetsForFilter}
        risks={txRisksForFilter}
        setRisks={setTxRisksForFilter}
        price={priceForFilter}
        setPrice={setPriceForFilter}
        handleApplyFilters={handleApplyFilters}
        disabled={isLoading}
        currency={currency}
        hiddenFilters={[...hiddenFilters, ...unsupportedFilters]}
      />}
      <div className={styles.consolidatedTransactions}>
        <div className={clsx(styles.tableHeader, styles.tableHeaderConcise)}>
          <span></span>
          <span>Date and Time</span>
          <span>Account</span>
          <span>Type</span>
          <span>Transfer</span>
          <span>Sum</span>
          <span>Risk score</span>
        </div>
        <div className={styles.tableBody}>
          {transactions.map((transaction, n) => (
            <div key={`transaction-row-${transaction?.transaction_id}-${n}`}>
              <ConsolidatedTransactionsTableRowConcise
                data={transaction}
                accountInfo={accountInfoByAccountId[transaction.account_id]}
                ownMovementsLabel={accountInfoByAccountId[transaction.account_id]?.description}
                otherRowIsExpanded={expandedRowIndex !== n}
                onExpand={() => setExpandedRowIndex(n)}
                currency={currency}
                riskLabelsOnly={true}
              />
              <div className={styles.rowDivider}></div>
            </div>
          ))}
          {(transactions ?? []).length === 0 && !isLoading && <div className={styles.infoMessageContainer}>
            <div className={styles.infoMessage}>
              There are no transactions to display.
            </div>
          </div>}
        </div>
      </div>

      <div className={styles.loadMoreContainer}>
        {isLoading
          ? <div className={styles.loadingText}>
            {totalPages === undefined
              ? <div className={styles.loaderWithTextContainer}>
                <ClipLoader color='#888888' size={15} />
                Loading
              </div>
              : <div className={styles.loaderWithTextContainer}>
                <ClipLoader color='#888888' size={15} />
                Investigating chunk {lastLoadedPage} / {totalPages}
              </div>
            }
          </div>
          : <span className={styles.loadMoreText} onClick={() => showMoreTxs()}>
            {canShowMoreTxs && 'Load more'}
          </span>
        }
      </div>
    </CustomerDetailsBox>
  );
};

export default TransactionsTable;
