import axios from 'axios';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { transformToAccountTx } from '../tx-transformers/blockmate';

const loadRiskTxsPage = async ({
  chain,
  page,
  ownerAddr,
  exchangeRates,
  fiatCurrency = 'usd',
  riskDetail,
  apiUrl,
  authKey
}) => {
  const investigatedTxsResponse = await axios.get(
    `${apiUrl}/v1/aggregate/risk_transactions`,
    {
      params: {
        chain,
        page: page,
        'owner-address': ownerAddr,
      },
      headers: {
        'Authorization': `Bearer ${authKey}`,
      },
    }
  );

  const resData = investigatedTxsResponse?.data;
  const txs = resData?.transactions ?? [];
  const investigatedPage = resData?.page;
  const totalPages = resData?.total_pages;

  const transformedTxs = txs.map(
    tx => transformToAccountTx(tx, ownerAddr, 'ETH-01', exchangeRates, fiatCurrency)
  );

  const txsWithRisk = addMisttrackRiskToTxs(transformedTxs, riskDetail, chain);
  return {
    transactions: txsWithRisk,
    page: investigatedPage,
    totalPages,
  };
};

export default loadRiskTxsPage;
