export const SOURCE_TYPE_KEYS = {
  boughtOnCex: 'BOUGHT_ON_CEX',
  profitsFromCexTrading: 'PROFITS_FROM_CEX_TRADING',
  mining: 'MINING',
  staking: 'STAKING',
  airdrop: 'AIRDROP',
  swap: 'SWAP',
  lendingAndLiquidity: 'LENDING_AND_LIQUIDITY_POOLS',
  ico: 'ICO',
  income: 'INCOME',
};

export const CUSTOMER_TYPE_KEYS = {
  individual: 'INDIVIDUAL',
  company: 'COMPANY',
};
