import {
  getSimpleTransactionFee, getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx
} from '../../../utils/account-tx-parser';

const getCreatedAt = (transaction) => {
  const epochTime = transaction.timestamp;
  return new Date(epochTime * 1000);
};

export const transformToAccountTx = (heliusTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const SOL_CURRENCY_FACTOR = 1000000000;
  const currency = 'SOL';

  let type = 'transfer';
  const originatorPaysFee = heliusTx.feePayer.toLowerCase() === originatorAddress.toLowerCase();
  const toOriginatorOutputExists = [...(heliusTx.nativeTransfers ?? []), ...(heliusTx.tokenTransfers ?? [])].some(
    transferInfo => transferInfo?.toUserAccount.toLowerCase() === originatorAddress.toLowerCase()
  );
  if (originatorPaysFee && !toOriginatorOutputExists) {
    type = 'crypto withdrawal';
  } else if (!originatorPaysFee && toOriginatorOutputExists) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(heliusTx),
    transaction_id: heliusTx.signature,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  // SOL transfers
  heliusTx.nativeTransfers.forEach(solTransfer => {
    const inputAddress = solTransfer.fromUserAccount;
    const amount = solTransfer.amount / SOL_CURRENCY_FACTOR;

    const inputMovement = {
      address: inputAddress,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: amount,
      fee: false,
      owned: inputAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    const outputAddress = solTransfer.toUserAccount;
    const outputMovement = {
      address: outputAddress,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: amount,
      fee: false,
      owned: outputAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    accountTransaction.inputs.push(inputMovement);
    accountTransaction.outputs.push(outputMovement);
  });

  // SPL transfers
  heliusTx.tokenTransfers.forEach(tokenTransfer => {
    const fromAddress = tokenTransfer.fromUserAccount;
    const toAddress = tokenTransfer.toUserAccount;
    const currencyString = `${currency}:${tokenTransfer.mint}`;
    const amount = tokenTransfer.tokenAmount;

    const splInput = {
      address: fromAddress,
      currency: currencyString,
      currency_name: currencyString,
      currency_symbol: currencyString,
      value: amount,
      fee: false,
      owned: fromAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    const splOutput = {
      address: toAddress,
      currency: currencyString,
      currency_name: currencyString,
      currency_symbol: currencyString,
      value: amount,
      fee: false,
      owned: toAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    accountTransaction.inputs.push(splInput);
    accountTransaction.outputs.push(splOutput);
  });

  // Add fee
  const fee = heliusTx.fee / SOL_CURRENCY_FACTOR;
  const feePayerAddress = heliusTx.feePayer;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress.toLowerCase() === originatorAddress.toLowerCase(),
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};
