import { getTrxChainConfig } from './trx-chain-config';
import { getSolChainConfig } from './sol-chain-config';
import { getBscChainConfig } from './bsc-chain-config';
import { getBchChainConfig } from './bch-chain-config';
import { getLtcChainConfig } from './ltc-chain-config';
import { getDogeChainConfig } from './doge-chain-config';
import { getTonChainConfig } from './ton-chain-config';
import { getBtcEthChainConfig } from './btc-eth-chain-config-internal';

export const getChainConfig = ({
  chain,
  address,
  accountId,
  currency,
  highRiskOnly = false,
  externalApiKeys,
  blockmateApiUrl,
  riskDetail,
  accountUuidToDescription, // For internal chains only, looks like {[account_uuid]: 'BTC-01', ...}
}) => {
  const chainConfigParams = { address, accountId, currency, highRiskOnly, riskDetail };
  if (chain === 'trx') {
    return getTrxChainConfig({
      ...chainConfigParams,
      externalApiKey: externalApiKeys.tronscan,
    });
  } else if (chain === 'sol') {
    return getSolChainConfig({
      ...chainConfigParams,
      externalApiKey: externalApiKeys.helius,
    });
  } else if (chain === 'bnb') {
    return getBscChainConfig({
      ...chainConfigParams,
      externalApiKey: externalApiKeys.bscscan,
    });
  } else if (chain === 'bch') {
    return getBchChainConfig(chainConfigParams);
  } else if (chain === 'ltc') {
    return getLtcChainConfig(chainConfigParams);
  } else if (chain === 'doge') {
    return getDogeChainConfig({
      ...chainConfigParams,
      externalApiKey: externalApiKeys.tatum,
    });
  } else if (chain === 'ton') {
    return getTonChainConfig({
      ...chainConfigParams,
      externalApiKey: externalApiKeys.toncenter,
    });
  } else if (['btc', 'eth'].includes(chain)) {
    return getBtcEthChainConfig({
      ...chainConfigParams,
      externalApiKey: externalApiKeys.blockmateUserJwt,
      externalApiBaseUrl: blockmateApiUrl,
      accountUuidToDescription,
    });
  } else {
    console.error(`Chain ${chain} not supported yet.`);
  }
};
