import { maybeAddConvertedValuesToTx } from '../../../utils/account-tx-parser';

export const transformToAccountTx = (blockmateTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  let chain = 'BTC';
  if (originatorAddress.startsWith('0x')) {
    chain = 'ETH';
  }
  let type = 'transfer';
  const totalOwnInValue = (blockmateTx?.inputs ?? [])
    .filter(input => input?.address.toLowerCase() === originatorAddress.toLowerCase())
    .reduce((acc, input) => acc + Number(input?.value ?? 0), 0);
  const totalOwnOutValue = (blockmateTx?.outputs ?? [])
    .filter(output => output.address.toLowerCase() === originatorAddress.toLowerCase())
    .reduce((acc, output) => acc + Number(output?.value ?? 0), 0);
  if (totalOwnInValue > totalOwnOutValue) {
    type = 'crypto withdrawal';
  } else if (totalOwnInValue < totalOwnOutValue) {
    type = 'crypto deposit';
  }
  blockmateTx.type = type;

  maybeAddConvertedValuesToTx(blockmateTx, exchangeRates, chain, fiatCurrency);

  return blockmateTx;
};
