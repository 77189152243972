import { valueExists } from '../../../utils/common';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from '../../api/axios';
import { transformToAccountTx } from '../tx-transformers/tronscan';
import { determineTxAcceptance } from '../../../utils/account-tx-parser';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { isHighRisk } from '../../../utils/riskLevels';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';

export const getTrxChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, riskDetail }) => {
  const pageSize = 50;

  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
      lastLoadedPage,
      totalPages,
    };
    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;

      if (valueExists(accountsFilterParam)) {
        if (!accountsFilterParam.split(',').includes(accountId)) {
          updatedValues.nextPageExists = false;
          return updatedValues;
        }
      }
      if (valueExists(digitalAssetsFilterParam)) {
        if (!digitalAssetsFilterParam.split(',').includes('TRX')) {
          updatedValues.nextPageExists = false;
          return updatedValues;
        }
      }
    }

    if (updatedValues.cursorValue === undefined) {
      updatedValues.cursorValue = 0;
    }

    const params = {
      sort: '-timestamp',
      count: true,
      start: updatedValues.cursorValue,
      limit: pageSize,
      address,
    };

    const headers = { 'TRON-PRO-API-KEY': externalApiKey };

    const url = 'https://apilist.tronscanapi.com/api/transaction';
    let data = [];
    try {
      const response = await axios.get(
        url,
        {
          params,
          headers,
        }
      );
      data = response?.data?.data ?? [];
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error getting Tron transactions from explorer, data might be incomplete'));
    }

    const accountTransactions = data
      .map(tronscanTx => transformToAccountTx(tronscanTx, address, accountId, exchangeRates, currency))
      .filter(accountTx => determineTxAcceptance(accountTx));
    let accountTransactionsWithRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, 'trx');
    updatedValues.cursorValue += accountTransactionsWithRisk.length;
    if (highRiskOnly) {
      accountTransactionsWithRisk = accountTransactionsWithRisk.filter(tx => isHighRisk(tx?.risk ?? 0));
    }

    updatedValues.txsBuffer = [...updatedValues.txsBuffer, ...accountTransactionsWithRisk];
    return updatedValues;
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, getSupportedFilters };
};
