import useTxsLoaderBase from './txsLoaderBase';
import { getTrxChainConfig } from '../../chain-config/trx-chain-config';
import useExternalApiKeys from '../externalApiKeys';

const useTronTxsLoader = ({ address, exchangeRates, currency, accountId, riskDetail, highRiskOnly = true, filters }) => {
  const { externalApiKeys } = useExternalApiKeys();

  const tronChainConfig = getTrxChainConfig({
    address,
    accountId,
    currency,
    highRiskOnly,
    riskDetail,
    externalApiKey: externalApiKeys?.tronscan,
  });

  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: tronChainConfig,
  });

  return { getNextTx, hasNextTx, reset, getLastLoadedPage, getTotalPages };
};

export default useTronTxsLoader;

