import styles from './knowledge-hub.module.scss';
import ModuleCard from '../../components/card/ModuleCard';
import { ReactComponent as DocumentIcon } from '../../assets/images/svg/file.svg';
import { ReactComponent as ListIcon } from '../../assets/images/svg/list-icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/svg/email.svg';
import routes from '../../routes/routes';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';


const KnowledgeHub = () => {
  const navigate = useNavigate();

  const modules = [
    {
      label: 'Proof of funds',
      icon: <DocumentIcon />,
      route: routes.home.knowledgeHub.proofOfFunds,
      allowed: true,
    },
    {
      label: 'List of CASP/VASP',
      icon: <ListIcon />,
      route: routes.home.knowledgeHub.caspVaspList,
      allowed: true,
    },
    {
      label: 'AML email generator',
      icon: <EmailIcon />,
      route: routes.home.knowledgeHub.amlEmailGenerator,
      allowed: true,
    },
  ];
  
  return <div className={styles.container}>
    <Header headerTitle='Knowledge hub' />
    <div className={styles.moduleCards}>
      {modules.filter(module => module.allowed).map(module =>
        <ModuleCard
          icon={module.icon}
          text={module.label}
          handleClick={() => navigate(module.route)}
          key={`module-${module.label}`}
        />
      )}
    </div>
  </div>;
};

export default KnowledgeHub;
