import styles from '../customer-details/style.module.scss';
import { useOutletContext } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../context/user/UserContext';
import { ReactComponent as SearchIcon } from '../../assets/images/svg/search.svg';
import { ReactComponent as EarthIcon } from '../../assets/images/svg/earth-icon.svg';
import ConnectedAccounts from '../customer-details/ConnectedAccounts';
import { clsx } from 'clsx';
import NoAccountMessage from '../../components/explorer-like/NoAccountMessage';
import AddressInput from '../../components/address-input/AddressInput';
import useTxsMultiloader from '../../services/chain-data-loaders/hooks/multiloaders/txsMultiloader';
import { toast } from 'react-hot-toast';
import { padWithZeros, sleep, valueExists } from '../../utils/common';
import { getExternalConnectedAccount } from '../../services/chain-data-loaders/blockmate-api';
import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from '../../services/api/axios';
import jwt_decode from 'jwt-decode';
import { getChainConfig } from '../../services/chain-data-loaders/chain-config/chain-config-factory';
import TransactionsTable from '../../components/transaction-risk-categories/TransactionsTable';
import { operationTypes } from '../../services/operations-logger/logger-service';
import useLoggerService from '../../services/operations-logger/logger-service';
import useExternalApiKeys from '../../services/chain-data-loaders/hooks/externalApiKeys';
import ConsolidatedTransactionsButtons
  from '../../components/consolidated-transactions/ConsolidatedTransactionsButtons';
import CustomerDetailsBox from '../customer-details/CustomerDetailsBox';
import getExchangeRates from '../../services/api/exchange-rates';
import Loader from '../../components/loaders/loader/Loader';
import { filterTypes } from '../../components/filters/ConsolidatedTransactionsFilters';

// Filtering-related constants
const TX_TYPES = [
  {
    key: 'crypto deposit',
    label: 'Crypto deposit',
  },
  {
    key: 'crypto withdrawal',
    label: 'Crypto withdrawal',
  },
  {
    key: 'fiat deposit',
    label: 'Fiat deposit',
  },
  {
    key: 'fiat withdrawal',
    label: 'Fiat withdrawal',
  },
  {
    key: 'transfer',
    label: 'Transfer',
  },
];

const DIGITAL_ASSETS = [
  {
    key: 'BTC',
    label: 'Bitcoin',
  },
  {
    key: 'ETH',
    label: 'Ethereum',
  },
  {
    key: 'TRX',
    label: 'Tron',
  },
  {
    key: 'BCH',
    label: 'Bitcoin Cash',
  },
  {
    key: 'BNB',
    label: 'BNB',
  },
  {
    key: 'DOGE',
    label: 'Dogecoin',
  },
  {
    key: 'LTC',
    label: 'Litecoin',
  },
  {
    key: 'SOL',
    label: 'Solana',
  },
  {
    key: 'TON',
    label: 'The Open Network',
  },
];

const TX_RISKS = [
  {
    key: 'low',
    label: 'Low',
  },
  {
    key: 'medium',
    label: 'Medium',
  },
  {
    key: 'high',
    label: 'High',
  },
];

const Explorer = () => {
  const { logOperation } = useLoggerService();

  const DISPLAY_PAGE_SIZE = 50;

  const allChainTickers = ['btc', 'eth', 'trx', 'bch', 'bnb', 'doge', 'ltc', 'sol', 'ton'];

  const { explorerProject } = useOutletContext();
  const { userEmail, getConfig, companyCurrency } = useContext(UserContext);

  const pageLoaded = useMemo(() => {
    return !!explorerProject;
  }, [explorerProject]);

  const [exchangeRates, setExchangeRates] = useState({});

  const [connectedAddress, setConnectedAddress] = useState('');
  const [currentChain, setCurrentChain] = useState('btc');
  const [nextAccountIdByChain, setNextAccountIdByChain] = useState(
    Object.fromEntries(allChainTickers.map(ticker => [ticker, 1]))
  );
  const [balanceData, setBalanceData] = useState();
  const [connectedExternalAddresses, setConnectedExternalAddresses] = useState(0);

  const [temporaryUserName, setTemporaryUserName] = useState('');
  const [temporaryUserJwt, setTemporaryUserJwt] = useState('');
  const [temporaryUserId, setTemporaryUserId] = useState('');
  const [accountUuidToDescription, setAccountUuidToDescription] = useState({});

  const txsMultiloader = useTxsMultiloader({ exchangeRates });
  const [transactions, setTransactions] = useState([]);
  const [isLoadingTxs, setIsLoadingTxs] = useState(false);
  const [canShowMoreTxs, setCanShowMoreTxs] = useState(true);

  const { externalApiKeys } = useExternalApiKeys();

  const [accountInfoByAccountId, setAccountInfoByAccountId] = useState({});
  const [connectedAddresses, setConnectedAddresses] = useState([]);

  // Filtering-related states
  const [accountsForFilter, setAccountsForFilter] = useState([]);
  const [txTypesForFilter, setTxTypesForFilter] = useState(
    TX_TYPES.map(txType => ({ ...txType, checked: false }))
  );
  const [digitalAssetsForFilter, setDigitalAssetsForFilter] = useState(
    DIGITAL_ASSETS.map(asset => ({ ...asset, checked: false }))
  );
  const [txRisksForFilter, setTxRisksForFilter] = useState(
    TX_RISKS.map(txRisk => ({ ...txRisk, checked: false }))
  );
  const [priceForFilter, setPriceForFilter] = useState({ min: '', max: '' });
  const allFilterTypes = [filterTypes.ACCOUNTS, filterTypes.ASSETS, filterTypes.PRICE, filterTypes.RISKS, filterTypes.TYPES];
  const [supportedFilters, setSupportedFilters] = useState(allFilterTypes);
  const unsupportedFilters = useMemo(() => {
    const allFilters = Object.entries(filterTypes).map(([_, filterType]) => filterType);
    return allFilters.filter(filterType => !supportedFilters.includes(filterType));
  }, [supportedFilters]);
  const hiddenFilters = [filterTypes.RISKS];
  const buildFilterParam = (filterArray) => {
    return (filterArray ?? []).filter(option => option.checked).map(option => option.key).join(',');
  };
  const resetFilters = () => {
    const uncheck = (element => ({ ...element, checked: false }));
    setAccountsForFilter(prev => prev.map(uncheck));
    setDigitalAssetsForFilter(prev => prev.map(uncheck));
    setPriceForFilter({ min: '', max: '' });
    setTxRisksForFilter(prev => prev.map(uncheck));
    setTxTypesForFilter(prev => prev.map(uncheck));
    txsMultiloader.setFilters(undefined);
  };
  const accountsFilterParam = useMemo(
    () => buildFilterParam(accountsForFilter),
    [JSON.stringify(accountsForFilter)]
  );
  const txTypesFilterParam = useMemo(
    () => buildFilterParam(txTypesForFilter),
    [JSON.stringify(txTypesForFilter)]
  );
  const digitalAssetsFilterParam = useMemo(
    () => buildFilterParam(digitalAssetsForFilter),
    [JSON.stringify(digitalAssetsForFilter)]
  );
  const txRisksFilterParam = useMemo(
    () => buildFilterParam(txRisksForFilter),
    [JSON.stringify(txRisksForFilter)]
  );
  const minPriceFilterParam = valueExists(priceForFilter.min) ? priceForFilter.min : undefined;
  const maxPriceFilterParam = valueExists(priceForFilter.max) ? priceForFilter.max : undefined;
  const filters = useMemo(() => ({
    accountsFilterParam,
    txTypesFilterParam,
    digitalAssetsFilterParam,
    txRisksFilterParam,
    minPriceFilterParam,
    maxPriceFilterParam,
  }), [accountsFilterParam, txTypesFilterParam, digitalAssetsFilterParam, txRisksFilterParam, minPriceFilterParam, maxPriceFilterParam]);

  const handleApplyFilters = async () => {
    txsMultiloader.setFilters(filters);
    await resetTxs();
  };

  const fetchExchangeRates = async () => {
    const cryptoCurrencies = ['usdt', 'eth', 'trx', 'bch', 'bnb', 'doge', 'ltc', 'sol', 'ton'];
    const pairs = cryptoCurrencies.map(cryptoCurrency => `${cryptoCurrency}/${companyCurrency}`);
    const userName = getTemporaryUserName();
    const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
    const rates = await getExchangeRates(pairs, getConfig('api_url'), userJwt);
    const simplifiedRates = {};
    cryptoCurrencies.forEach(cryptoCurrency => {
      const pair = `${cryptoCurrency}/${companyCurrency}`.toLowerCase();
      simplifiedRates[cryptoCurrency] = rates[pair];
    });
    setExchangeRates(simplifiedRates);
  };

  const getTemporaryUserName = () => {
    if (valueExists(temporaryUserName)) {
      return temporaryUserName;
    }
    const userName = `${userEmail}-${Date.now()}`;
    setTemporaryUserName(userName);
    return userName;
  };

  const getTemporaryUserJwt = async (projectId, userName) => {
    try {
      const response = await axios.get(
        getStaticPath('APIAUTH_URL', `user/${projectId}/cluid`),
        {
          params: {
            cluid: encodeURIComponent(userName)
          }
        }
      );
      const jwt = response.data.token;
      setTemporaryUserJwt(jwt);
      const decoded = jwt_decode(jwt);
      setTemporaryUserId(decoded?.user_uuid);
      return jwt;
    } catch (error) {
      toast.error(axiosErrorHandler(error, 'Error getting a temporary auth token. Please try again later'));
    }
  };

  const resetTxs = async () => {
    setTransactions([]);
    txsMultiloader.reset();
    await showMoreTxs();
  };

  const getRiskDetail = async (address, chain) => {
    const userName = getTemporaryUserName();
    const jwt = await getTemporaryUserJwt(explorerProject.id, userName);
    try {
      const response = await axios.get(
        '/v1/quick_risk',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
          params: {
            chain,
            address,
          },
        }
      );
      return response?.data?.risk_result?.risk_detail ?? [];
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Failed to get risk data, data might be incomplete'));
      return [];
    }
  };

  const connectAddressInternal = async (projectId, chain, address, description, userName, userJwt) => {
    const response = await axios.post(
      getStaticPath('ACCOUNT_URL', 'connect'),
      {},
      {
        baseUrl: getConfig('api_url'),
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${userJwt}`,
          'content-type': 'application/json'
        },
        params: {
          user_jwt: userJwt,
          chain: chain.toLowerCase(),
          wallet: address,
          description: description ?? address
        }
      }
    );
    return response?.data?.id;
  };

  const handleConnect = async () => {
    if (!currentChain) {
      toast.error('The provided address does not belong to any of the supported chains.');
      return;
    }
    if (connectedAddresses.includes(connectedAddress)) {
      toast.error('Address already connected');
      return;
    }

    resetFilters();

    setIsLoadingTxs(true);
    setTransactions([]);

    const accountChainSeqNumber = nextAccountIdByChain[currentChain];
    const accountId = `${currentChain.toUpperCase()}-${padWithZeros(accountChainSeqNumber, 2)}`;

    const userName = getTemporaryUserName();
    const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
    const newlyConnectedAccount = await getExternalConnectedAccount(
      currentChain, connectedAddress, userJwt, getConfig('api_url'), accountId, companyCurrency
    );

    const newAccountUuidToDescription = { ...accountUuidToDescription };
    let uuid;
    if (['btc', 'eth'].includes(currentChain)) {
      try {
        // For internal chains (btc, eth), we also want to store the account's uuid, so we can disconnect it later
        uuid = await connectAddressInternal(explorerProject.id, currentChain, connectedAddress, accountId, userName, userJwt);
        newAccountUuidToDescription[uuid] = accountId; // Need this locally due to asynchronous updates of state
        setAccountUuidToDescription(newAccountUuidToDescription);
        newlyConnectedAccount.account_uuid = uuid;
      } catch (err) {
        toast.error(axiosErrorHandler(err, 'Error connecting address'));
        await resetTxs();
        setIsLoadingTxs(false);
        return;
      }
    } else {
      setConnectedExternalAddresses(prev => prev + 1);
    }

    setBalanceData(prevBalanceData => ({
      balance_sum: prevBalanceData?.balance_sum,
      accounts: [
        ...(prevBalanceData?.accounts ?? []),
        newlyConnectedAccount,
      ]
    }));
    setAccountInfoByAccountId(prevAccountsById => ({
      ...prevAccountsById,
      [accountId]: {
        address: connectedAddress,
        description: accountId,
      },
    }));
    const riskDetail = await getRiskDetail(connectedAddress, currentChain);
    const chainConfig = getChainConfig({
      chain: currentChain,
      address: connectedAddress,
      accountId,
      currency: companyCurrency,
      highRiskOnly: false,
      externalApiKeys: { ...externalApiKeys, blockmateUserJwt: userJwt },
      riskDetail,
      blockmateApiUrl: getConfig('api_url'),
      accountUuidToDescription: newAccountUuidToDescription,
    });
    txsMultiloader.addAddress(currentChain, connectedAddress, chainConfig);

    await logOperation(operationTypes.explorer, explorerProject.id, currentChain, connectedAddress);

    // Update counters and reset address input
    setNextAccountIdByChain(prevNextAccountIdByChain => ({
      ...prevNextAccountIdByChain,
      [currentChain]: accountChainSeqNumber + 1
    }));
    setConnectedAddresses(prev => [...prev, connectedAddress]);
    setConnectedAddress('');
    setAccountsForFilter(prev => {
      return [
        ...prev,
        {
          key: uuid ?? accountId,
          label: accountId,
          checked: false,
        }
      ];
    });

    await resetTxs();
    setIsLoadingTxs(false);
  };

  const handleDisconnect = async (accountId) => {
    const accountDetails = (balanceData?.accounts ?? []).find(account => account.account_id === accountId);
    const isInternalChain = ['btc', 'eth'].includes(accountDetails?.name);
    if (isInternalChain) {
      const accountUrl = `${accountDetails.url}/account/${accountDetails?.account_uuid}`;
      const userName = getTemporaryUserName();
      const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
      try {
        await axios.delete(
          `/v1/${accountUrl}`,
          {
            baseURL: getConfig('api_url'),
            headers: {
              'Authorization': `Bearer ${userJwt}`,
            }
          }
        );
      } catch (error) {
        toast.error(axiosErrorHandler(error, 'Error disconnecting account. Please try again'));
        return;
      }
    } else {
      setConnectedExternalAddresses(prev => prev - 1);
    }
    txsMultiloader.removeAddress(accountDetails?.name, accountDetails?.wallet);
    setAccountsForFilter(prev => prev.filter(details => details.label !== accountId));
    setConnectedAddresses(prev => prev.filter(addr => addr !== accountDetails?.wallet));
    setBalanceData(prevBalanceData => ({
      accounts: prevBalanceData?.accounts.filter(account => account.account_id !== accountId)
    }));
    await handleApplyFilters();
  };

  const showMoreTxs = async () => {
    const newTxs = [];
    setIsLoadingTxs(true);
    const MAX_RETRIES = 4;
    let retriesRemaining = MAX_RETRIES;
    while (newTxs.length < DISPLAY_PAGE_SIZE && txsMultiloader.hasNextTx()) {
      try {
        const nextTx = await txsMultiloader.getNextTx(false);
        if (nextTx) {
          newTxs.push(nextTx);
        }
        retriesRemaining = MAX_RETRIES;
      } catch (err) {
        retriesRemaining -= 1;
        if (retriesRemaining === 0) {
          toast.error(
            'The server is too busy at the moment. Please try again in 5 minutes. If the issue persists, please contact us.'
          );
          break;
        } else {
          console.error(err);
          toast.error('Failed to load transactions. Retrying now.');
          await sleep(1000);
        }
      }
    }
    setTransactions(prevTransactions => [...prevTransactions, ...newTxs]);
    setCanShowMoreTxs(txsMultiloader.hasNextTx());
    setIsLoadingTxs(false);
  };

  useEffect(() => {
    if (explorerProject) {
      fetchExchangeRates()
        .catch(
          err => {
            console.error(err);
            toast.error(axiosErrorHandler(err, 'Failed to get exchange rates, data might be incomplete.'));
          }
        );
    }
  }, [explorerProject]);

  useEffect(() => {
    setSupportedFilters(txsMultiloader.getSupportedFilters());
  }, [connectedAddresses]);

  if (!pageLoaded) {
    return <Loader />;
  }

  return <div className='page-content-container'>
    <div className={clsx(styles.customers, 'explorer-container')}>

      <AddressInput
        connectedAddress={connectedAddress}
        setConnectedAddress={setConnectedAddress}
        currentChain={currentChain}
        setCurrentChain={setCurrentChain}
        handleConnect={handleConnect}
        chainFilter={allChainTickers}
        buttonLabel={balanceData ? 'ADD ACCOUNT' : 'EXPLORE ACCOUNT'}
        disabled={isLoadingTxs}
      />

      {(connectedAddresses.length === 0 && !isLoadingTxs) &&
        <div className='no-accounts-info'>
          <NoAccountMessage
            icon={<SearchIcon />}
            title='Explore accounts'
            description={
              'Please paste address above in the input field to search and explore crypto accounts. ' +
              'Account\'s chain will be automatically detected.'
            }
          />
        </div>
      }

      {(connectedAddresses.length > 0 || isLoadingTxs) && <ConnectedAccounts
        balanceData={balanceData}
        reducedInfo
        expandable={true}
        currency={companyCurrency ?? 'USD'}
        canDisconnect={!isLoadingTxs}
        handleDisconnect={handleDisconnect}
      />}

      {(connectedAddresses.length > 0 || isLoadingTxs) && <CustomerDetailsBox
        title='Consolidated transactions'
        withHeader={true}
        icon={<EarthIcon/>}
        buttons={<ConsolidatedTransactionsButtons
          userId={temporaryUserId}
          projectId={explorerProject.id}
          userJWT={temporaryUserJwt}
          exportAllowed={connectedExternalAddresses === 0 && !isLoadingTxs}
        />}
        concise={true}
        disableBoxShadow={false}
      >
        <TransactionsTable
          transactions={transactions}
          isLoading={isLoadingTxs}
          accountInfoByAccountId={accountInfoByAccountId}
          currency={companyCurrency}
          canShowMoreTxs={canShowMoreTxs}
          showMoreTxs={showMoreTxs}
          accountsForFilter={accountsForFilter}
          setAccountsForFilter={setAccountsForFilter}
          txTypesForFilter={txTypesForFilter}
          setTxTypesForFilter={setTxTypesForFilter}
          digitalAssetsForFilter={digitalAssetsForFilter}
          setDigitalAssetsForFilter={setDigitalAssetsForFilter}
          txRisksForFilter={txRisksForFilter}
          setTxRisksForFilter={setTxRisksForFilter}
          priceForFilter={priceForFilter}
          setPriceForFilter={setPriceForFilter}
          unsupportedFilters={unsupportedFilters}
          hiddenFilters={hiddenFilters}
          handleApplyFilters={handleApplyFilters}
        />
      </CustomerDetailsBox>}
    </div>
  </div>;
};

export default Explorer;
