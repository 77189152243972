import { getRandomElement } from '../../../../utils/common';
import useTxsLoaderBase from './txsLoaderBase';
import { getSolChainConfig } from '../../chain-config/sol-chain-config';
import useExternalApiKeys from '../externalApiKeys';

const useSolTxsLoader = ({ address, exchangeRates, currency, accountId, riskDetail, highRiskOnly }) => {
  const { externalApiKeys } = useExternalApiKeys();
  const heliusApiKey = getRandomElement(externalApiKeys.helius.split(','));

  const solChainConfig = getSolChainConfig({
    address,
    accountId,
    currency,
    highRiskOnly,
    riskDetail,
    externalApiKey: heliusApiKey,
  });

  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: solChainConfig,
  });

  return { getNextTx, hasNextTx, reset, getLastLoadedPage, getTotalPages };
};

export default useSolTxsLoader;
