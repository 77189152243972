import { sleep, valueExists } from '../../../utils/common';
import axios from 'axios';
import { transformToAccountTx } from '../tx-transformers/toncenter';
import { determineTxAcceptance } from '../../../utils/account-tx-parser';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { isHighRisk } from '../../../utils/riskLevels';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';

export const getTonChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, riskDetail }) => {
  const pageSize = 100;

  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;
      if (valueExists(accountsFilterParam) && !accountsFilterParam.split(',').includes(accountId)) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
      if (valueExists(digitalAssetsFilterParam) && !digitalAssetsFilterParam.split(',').includes('TON')) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
    }

    const url = 'https://toncenter.com/api/v2/getTransactions';

    const params = {
      api_key: externalApiKey,
      address: address,
      limit: pageSize,
    };
    if (valueExists(updatedValues.cursorValue)) {
      params.to_lt = updatedValues.cursorValue - 1; // Logical time of the last tx
    }

    let data = [];
    let retriesRemaining = 5;
    let fetchedSuccessfully = false;
    while (!fetchedSuccessfully && retriesRemaining > 0) {
      try {
        const response = await axios.get(
          url,
          { params }
        );
        data = response?.data;
        if (!data?.ok) {
          await sleep(2000);
          retriesRemaining -= 1;
        }
        fetchedSuccessfully = true;
      } catch (err) {
        await sleep(2000);
        retriesRemaining -= 1;
      }
    }

    if (!fetchedSuccessfully) {
      updatedValues.nextPageExists = false;
      return updatedValues;
    }

    const fetchedToncenterTxs = data?.result ?? [];

    if (fetchedToncenterTxs.length < pageSize) {
      updatedValues.nextPageExists = false;
    }

    if (fetchedToncenterTxs.length === 0) {
      return updatedValues;
    }

    // Move cursor
    const oldestLogicalTime = fetchedToncenterTxs.slice(-1)?.[0]?.transaction_id?.lt;
    if (oldestLogicalTime) {
      updatedValues.cursorValue = oldestLogicalTime;
    }

    const logicalTimeByTxId = {};
    fetchedToncenterTxs.forEach(toncenterTx => {
      logicalTimeByTxId[toncenterTx.transaction_id.hash] = toncenterTx.transaction_id.lt;
    });

    const accountTransactions = fetchedToncenterTxs
      .map(toncenterTx => transformToAccountTx(toncenterTx, address, accountId, exchangeRates, currency))
      .filter(accountTx => determineTxAcceptance(accountTx));
    let accountTransactionsWithRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, 'ton');
    if (highRiskOnly) {
      accountTransactionsWithRisk = accountTransactionsWithRisk.filter(tx => isHighRisk(tx?.risk ?? 0));
    }

    updatedValues.txsBuffer = [...txsBuffer, ...accountTransactionsWithRisk];
    return updatedValues;
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, getSupportedFilters };
};
