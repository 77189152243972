import { CUSTOMER_TYPE_KEYS, SOURCE_TYPE_KEYS } from '../../utils/email-generator';

const generateSourceSnippet = (customerType, source) => {
  let text = '';
  if (source.type === SOURCE_TYPE_KEYS.boughtOnCex) {
    text = `
      Kryptomeny zakúpené na burzách, brokeroch alebo obchodných platformách (${source.cexName}):
      - Screenshot alebo výpis z účtu s potvrdením o nákupe a výbere, na ktorých je uvedené ID účtu alebo iný jedinečný identifikátor a všetky podrobnosti o transakcii ALEBO
      - Účtenky, ktoré poskytujú dôkaz o nákupe (napr. pomocou mimoburzového obchodu) A
      - Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
    `;
  } else if (source.type === SOURCE_TYPE_KEYS.profitsFromCexTrading) {
    text = `
      Zisky z obchodovania na burzách, brokeroch alebo obchodných platformách (${source.cexName}):
      - Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na účet zákazníka v Slovenskej Sporiteľni A
      - Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
    `;
  } else if (source.type === SOURCE_TYPE_KEYS.mining) {
    if (customerType === CUSTOMER_TYPE_KEYS.individual) {
      text = `
        Ťažba kryptomien (Mining):
        - Doklad o vlastníctve zariadení použitých pri ťažbe (napr. fotografie, účty za nákup) A
        - Screenshot ťažobnej platformy zobrazujúce odmeny, hashrate a počet ťažiarov
        - Screenshot alebo e-mailové potvrdenia zobrazujúce históriu transakcií z peňaženiek a do peňaženiek so zobrazením adries peňaženiek a hashov transakcií
      `;
    } else {
      text = `
        Ťažba kryptomien (Mining):
        - Doklad o vlastníctve zariadení použitých pri ťažbe (napr. fotografie, účty za nákup) A
        - Účtenky za nákup ťažobného hardvéru s uvedením adresy kupujúceho a predávajúceho. Hardvér musí byť zaslaný na adresu podniku, ktorá je vlastníkom účtu v Slovenskej Sporiteľni na ktorú smerovali prostriedky z transakcie a pre ktorý predkladáte doklad. Ak sa táto adresa medzičasom zmenila, preukážte, že podnik bol v tom čase registrovaný na tejto adrese
        - Fotografie ťažobného hardvéru a screenshot používaných platforiem a programov
        - Výpisy prevádzkových nákladov (napr. účty za elektrinu)
        - Screenshot o príjmoch z ťažby a odmenách
        - Screenshot procesu transakcií z a do súkromných a firemných peňaženiek
        - Ak sa obchodovalo aj s mincami z ťažby, je potrebné, aby ste poskytli screenshoty s históriou transakcií, na ktorých je jasne zobrazený príjem na príslušnej burze a meno majiteľa účtu.
        - Screenshot alebo e-mailové potvrdenia zobrazujúce históriu transakcií z peňaženiek a do peňaženiek so zobrazením adries peňaženiek a hashov transakcií
      `;
    }
  } else if (source.type === SOURCE_TYPE_KEYS.staking) {
    text = `
      Staking (Master Nodes):
      - Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stakingu A
      - Adresy peňaženiek a ID transakcií
    `;
  } else if (source.type === SOURCE_TYPE_KEYS.airdrop) {
    text = `
      Airdrops:
      - Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stávkovania A
      - Adresy peňaženiek a ID transakcií
    `;
  } else if (source.type === SOURCE_TYPE_KEYS.swap) {
    text = `
    Zámena mincí alebo tokenov (Swap):
    - Screenshot histórie transakcií (adresy peňaženiek a ID transakcií), na ktorých sú zobrazené výmeny a výbery smerujúce na účet zákazníka v Slovenskej Sporiteľni
    `;
  } else if (source.type === SOURCE_TYPE_KEYS.lendingAndLiquidity) {
    text = `
      Pôžičkové fondy a fondy likvidity (Lending a Liquidity pools):
      - Screenshot obrazovky s históriou transakcií (adresy peňaženiek a ID transakcií), na ktorých je jasne uvedený veriteľ/skupina likvidity, suma a pôvod vloženého kolaterálu
    `;
  } else if (source.type === SOURCE_TYPE_KEYS.ico) {
    text = `
      Prvotná ponuka mincí (ICO) a predaj tokenov:
      - Pre predaj tokenov: Podpísaná zmluva potvrdzujúca platbu v kryptomene
      - V prípade ICO: Podpísaná zmluva vrátane úplného názvu ICO
      - História transakcií (screenshot/e-mail) dokumentujúca nákup a výber tokenu (adresy peňaženiek a ID transakcie)
      - Výpis z bankového účtu, ak sa na investovanie použil fiat
    `;
  } else if (source.type === SOURCE_TYPE_KEYS.income) {
    text = `
      Príjem zo služieb:
      - Faktúry za služby, ktoré boli zaplatené v kryptomenách A
      - Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na učet zákazníka v Slovenskej Sporiteľni A
      - Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
    `;
  }
  return text;
};

export const generateEmail = (customerType, sources) => {
  const introduction = 'Vážený zákazník,\nžiadame Vás o doručenie nasledovných dokladov o pôvode Vašich kryptomien.';
  const mainText = sources.map(source => generateSourceSnippet(customerType, source)).join('\n');
  const ending = 'S pozdravom ...';
  return `${introduction}\n${mainText}\n${ending}`;
};
