import { valueExists } from '../../../utils/common';
import axios from 'axios';
import { axiosErrorHandler } from '../../api/axios';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';

export const getBtcEthChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, externalApiBaseUrl, riskDetail, accountUuidToDescription }) => {
  const pageSize = 50;

  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const END_CURSOR = '';

    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
    };

    const params = {
      page_size: pageSize,
      currency: currency ?? 'usd',
      risk2: 1,
    };
    if (updatedValues.cursorValue) {
      params.cursor = updatedValues.cursorValue;
    }

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
        txTypesFilterParam,
        txRisksFilterParam,
        minPriceFilterParam,
        maxPriceFilterParam
      } = filters;

      if (valueExists(accountsFilterParam)) {
        params['account-filter'] = accountsFilterParam;
      }
      if (valueExists(digitalAssetsFilterParam)) {
        params.currencies = digitalAssetsFilterParam;
      }
      if (valueExists(txTypesFilterParam)) {
        params.types = txTypesFilterParam;
      }
      if (valueExists(txRisksFilterParam)) {
        params.risks = txRisksFilterParam;
      }
      if (valueExists(minPriceFilterParam)) {
        params['min-price'] = minPriceFilterParam;
      }
      if (valueExists(maxPriceFilterParam)) {
        params['max-price'] = maxPriceFilterParam;
      }
    }

    let response;
    try {
      response = await axios.get('/v1/aggregate/transactions',
        {
          baseURL: externalApiBaseUrl,
          headers: {
            'Authorization': `Bearer ${externalApiKey}`,
          },
          params
        }
      );
    } catch (error) {
      return handleLoadError(error);
    }

    const { transactions, newCursorValue } = processResponseData(response?.data);
    updatedValues.cursorValue = newCursorValue;
    if (updatedValues.cursorValue === END_CURSOR) {
      updatedValues.nextPageExists = false;
    }
    updatedValues.txsBuffer = [...txsBuffer, ...transactions];
    return updatedValues;
  };

  const processResponseData = (data) => {
    const { transactions, page_cursor, risk2: misttrackRiskInfo } = data || {};

    if (transactions && page_cursor !== null) {

      const mergedMisttrackRiskDetail = Object.values(misttrackRiskInfo ?? {}).reduce((acc, singleAccountInfo) => {
        const thisAccountRiskDetail = singleAccountInfo?.risk_result?.risk_detail ?? [];
        return [...acc, ...thisAccountRiskDetail];
      }, []);

      const txsWithCustomRisk = addMisttrackRiskToTxs(transactions, mergedMisttrackRiskDetail);
      const txsWithAccountIds = txsWithCustomRisk.map(tx => ({
        ...tx,
        account_id: accountUuidToDescription[tx.account_id] // Translate uuid to BTC-01 etc.
      }));

      return {
        transactions: txsWithAccountIds,
        newCursorValue: page_cursor,
      };
    }
  };

  const handleLoadError = (error) => {
    if (error?.response?.status === 0 && error?.request) { // In case of 504, no response nor status are available
      throw new Error('Request is addressing too much data. Try reducing filters usage.');
    } else {
      throw new Error(axiosErrorHandler(error, 'Server error while loading transactions. Please try again later'));
    }
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS, filterTypes.PRICE, filterTypes.RISKS, filterTypes.TYPES];
  };

  return { loadNextPage, getSupportedFilters };
};
