import { useContext } from 'react';
import { UserContext } from '../../../context/user/UserContext';
import { getRandomElement } from '../../../utils/common';

const useExternalApiKeys = () => {
  const { getConfig } = useContext(UserContext);

  const externalApiKeys = {
    tronscan: getConfig('tronscan_api_key'),
    helius: getRandomElement(getConfig('helius_api_keys').split(',')),
    bscscan: getConfig('bscscan_api_key'),
    tatum: getConfig('tatum_api_key'),
    toncenter: getConfig('toncenter_api_key'),
  };

  return { externalApiKeys };
};

export default useExternalApiKeys;
