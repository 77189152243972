import { sleep, valueExists } from '../../../utils/common';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from '../../api/axios';
import { transformToAccountTx } from '../tx-transformers/helius';
import { determineTxAcceptance } from '../../../utils/account-tx-parser';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { isHighRisk } from '../../../utils/riskLevels';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';

export const getSolChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, riskDetail }) => {
  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;
      if (valueExists(accountsFilterParam) && !accountsFilterParam.split(',').includes(accountId)) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
      if (valueExists(digitalAssetsFilterParam) && !digitalAssetsFilterParam.split(',').includes('SOL')) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
    }

    const url = `https://api.helius.xyz/v0/addresses/${address}/transactions`;

    const pageSize = 100;

    const params = {
      'api-key': externalApiKey,
      limit: pageSize,
    };
    if (valueExists(cursorValue)) {
      params.before = cursorValue;
    }

    let data = [];
    let retriesLeft = 5;
    let fetchedSuccessfully = false;
    while(retriesLeft > 0 && !fetchedSuccessfully) {
      try {
        const response = await axios.get(
          url,
          { params }
        );
        data = response?.data ?? [];
        fetchedSuccessfully = true;
      } catch (err) {
        // Ignore too many requests errors
        if (err?.response?.status !== 429) {
          toast.error(axiosErrorHandler(err, 'Error getting Solana transactions from explorer, data might be incomplete'));
          return;
        } else {
          await sleep(2000);
          retriesLeft -= 1;
        }
      }
    }

    if (!fetchedSuccessfully) {
      toast.error('Could not load Solana transactions at the moment. If the issue persists after 5 minutes, please contact us.');
      return;
    }

    if (data.length < pageSize) {
      updatedValues.nextPageExists = false;
    }

    if (data.length > 0) {
      const oldestSignature = data.slice(-1)?.[0]?.signature;
      if (oldestSignature) {
        updatedValues.cursorValue = oldestSignature;
      }
    }

    const accountTransactions = data
      .map(heliusTx => transformToAccountTx(heliusTx, address, accountId, exchangeRates, currency))
      .filter(accountTx => determineTxAcceptance(accountTx));
    let accountTransactionsWithRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, 'sol');
    if (highRiskOnly) {
      accountTransactionsWithRisk = accountTransactionsWithRisk.filter(tx => isHighRisk(tx?.risk ?? 0));
    }

    updatedValues.txsBuffer = [...txsBuffer, ...accountTransactionsWithRisk];
    return updatedValues;
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, getSupportedFilters };
};
