import { useEffect, useRef } from 'react';
import { filterTypes } from '../../../../components/filters/ConsolidatedTransactionsFilters';

const useTxsMultiloader = ({ exchangeRates }) => {
  const INTERNAL = 'INTERNAL'; // Internal chains are all under this key. External addresses are keys on their own.

  const cursorValueRef = useRef({});
  const nextPageExistsRef = useRef({});
  const nextTxExistsRef = useRef({});
  const txsBufferRef = useRef({});
  const txsPointerRef = useRef({});

  const chainConfigRef = useRef({});

  const filtersRef = useRef();
  const exchangeRatesRef = useRef(exchangeRates);

  useEffect(() => {
    exchangeRatesRef.current = exchangeRates;
  }, [exchangeRates]);

  const getSingleAddressNextTx = async (address, peekOnly = false) => {
    if (!(address in nextTxExistsRef.current) || !(address in chainConfigRef.current)) {
      return undefined;
    }
    if (!nextTxExistsRef.current[address]) {
      return undefined;
    }
    if (txsPointerRef.current[address] >= txsBufferRef.current[address].length) {
      if (!nextPageExistsRef.current[address]) {
        nextTxExistsRef.current[address] = false;
        return undefined;
      }
      const { cursorValue, txsBuffer, nextPageExists } = await chainConfigRef.current[address].loadNextPage({
        cursorValue: cursorValueRef.current[address],
        txsBuffer: txsBufferRef.current[address],
        nextPageExists: nextPageExistsRef.current[address],
        exchangeRates: exchangeRatesRef.current,
        filters: filtersRef.current,
      });
      cursorValueRef.current[address] = cursorValue;
      txsBufferRef.current[address] = txsBuffer;
      nextPageExistsRef.current[address] = nextPageExists;
    }
    if (txsPointerRef.current[address] >= txsBufferRef.current[address].length) {
      nextTxExistsRef.current[address] = false;
      return undefined;
    }
    const tx = txsBufferRef.current[address][txsPointerRef.current[address]];
    if (!peekOnly) {
      txsPointerRef.current[address] += 1;
    }
    return tx;
  };

  const getNextTx = async (peekOnly = false) => {
    let newestFoundTxDate;
    let newestTxAddress;
    const connectedAddresses = Object.keys(cursorValueRef.current);
    for (const address of connectedAddresses) {
      const thisAddressTx = await getSingleAddressNextTx(address, true);
      if (!thisAddressTx) {
        nextTxExistsRef.current[address] = false;
        continue;
      }
      const thisTxDate = new Date(thisAddressTx.created_at);
      if (!newestFoundTxDate || thisTxDate > newestFoundTxDate) {
        newestFoundTxDate = thisTxDate;
        newestTxAddress = address;
      }
    }
    if (newestFoundTxDate === undefined) {
      return;
    }
    const newestTx = await getSingleAddressNextTx(newestTxAddress, peekOnly);
    return newestTx;
  };

  const hasNextTx = () => {
    const connectedAddresses = Object.keys(cursorValueRef.current);
    for (const address of connectedAddresses) {
      if (nextTxExistsRef.current[address]) {
        return true;
      }
    }
    return false;
  };

  const addAddress = (chain, address, chainConfig) => {
    if (address in cursorValueRef.current) {
      return;
    }
    reset();
    if (['btc', 'eth'].includes(chain)) {
      address = INTERNAL;
    }
    cursorValueRef.current[address] = undefined;
    nextPageExistsRef.current[address] = true;
    nextTxExistsRef.current[address] = true;
    txsBufferRef.current[address] = [];
    txsPointerRef.current[address] = 0;
    chainConfigRef.current[address] = chainConfig;
  };

  const removeAddress = (chain, address) => {
    if (!['btc', 'eth'].includes(chain)) {
      delete cursorValueRef.current[address];
      delete txsPointerRef.current[address];
      delete nextPageExistsRef.current[address];
      delete nextTxExistsRef.current[address];
      delete txsBufferRef.current[address];
      delete txsPointerRef.current[address];
      delete chainConfigRef.current[address];
    }
  };

  const setFilters = (filters) => {
    filtersRef.current = filters;
  };

  const getSupportedFilters = () => {
    const connectedAddresses = Object.keys(cursorValueRef.current);
    let supportedFilters = [filterTypes.ACCOUNTS, filterTypes.TYPES, filterTypes.ASSETS, filterTypes.PRICE, filterTypes.RISKS];
    connectedAddresses.forEach(address => {
      if (address in chainConfigRef.current) {
        const thisAddressSupports = chainConfigRef.current[address].getSupportedFilters();
        supportedFilters = supportedFilters.filter(supportedUntilNow => thisAddressSupports.includes(supportedUntilNow));
      }
    });
    return supportedFilters;
  };

  const reset = () => {
    const connectedAddresses = [...Object.keys(cursorValueRef.current), INTERNAL];
    for (const address of connectedAddresses) {
      nextTxExistsRef.current[address] = true;
      nextPageExistsRef.current[address] = true;
      txsPointerRef.current[address] = 0;
      cursorValueRef.current[address] = undefined;
      txsBufferRef.current[address] = [];
    }
  };

  return {
    getNextTx,
    hasNextTx,
    reset,
    addAddress,
    removeAddress,
    setFilters,
    getSupportedFilters,
  };
};

export default useTxsMultiloader;
