import axios from 'axios';
import { transformToAccountTx } from '../tx-transformers/bscscan';
import { determineTxAcceptance } from '../../../utils/account-tx-parser';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { isHighRisk } from '../../../utils/riskLevels';
import { valueExists } from '../../../utils/common';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';
 
export const getBscChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, riskDetail }) => {
  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
      lastLoadedPage,
      totalPages,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;
      if (valueExists(accountsFilterParam) && !accountsFilterParam.split(',').includes(accountId)) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
      if (valueExists(digitalAssetsFilterParam) && !digitalAssetsFilterParam.split(',').includes('BNB')) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
    }

    if (cursorValue === undefined) {
      updatedValues.cursorValue = 0;
    }
    updatedValues.cursorValue += 1;
    const response = await axios.get(
      'https://api.bscscan.com/api',
      {
        params: {
          module: 'account',
          action: 'txlist',
          address: address,
          page: updatedValues.cursorValue,
          offset: 1000, // This is limit, but bscscan calls it `offset`...
          sort: 'desc',
          startblock: 0,
          endblock: 99999999,
          apikey: externalApiKey,
        }
      }
    );
    if (response?.data?.result === undefined) {
      updatedValues.nextPageExists = false;
      return updatedValues;
    }
    const newBscScanTxs = response?.data?.result;
    if (newBscScanTxs.length === 0) {
      updatedValues.nextPageExists = false;
      return updatedValues;
    }

    const accountTransactions = newBscScanTxs
      .map(bscScanTx => transformToAccountTx(bscScanTx, address, undefined, exchangeRates, currency))
      .filter(accountTx => determineTxAcceptance(accountTx));
    let accountTransactionsWithRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, 'bnb');
    if (highRiskOnly) {
      accountTransactionsWithRisk = accountTransactionsWithRisk.filter(tx => isHighRisk(tx?.risk ?? 0));
    }

    updatedValues.txsBuffer = [...updatedValues.txsBuffer, ...accountTransactionsWithRisk];
    return updatedValues;
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, getSupportedFilters };
};
