import axios from 'axios';
import { transformToAccountTx } from '../tx-transformers/litecoinspace';
import { determineTxAcceptance } from '../../../utils/account-tx-parser';
import { addMisttrackRiskToTxs } from '../../../utils/misttrack';
import { isHighRisk } from '../../../utils/riskLevels';
import { valueExists } from '../../../utils/common';
import { filterTypes } from '../../../components/filters/ConsolidatedTransactionsFilters';

export const getLtcChainConfig = ({ address, accountId, currency, highRiskOnly = false, externalApiKey, riskDetail }) => {
  const loadNextPage = async ({
    cursorValue,
    txsBuffer,
    nextPageExists,
    lastLoadedPage,
    totalPages,
    exchangeRates,
    filters,
  }) => {
    const updatedValues = {
      cursorValue,
      txsBuffer,
      nextPageExists,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;
      if (valueExists(accountsFilterParam) && !accountsFilterParam.split(',').includes(accountId)) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
      if (valueExists(digitalAssetsFilterParam) && !digitalAssetsFilterParam.split(',').includes('LTC')) {
        updatedValues.nextPageExists = false;
        return updatedValues;
      }
    }

    let url = `https://litecoinspace.org/api/address/${address}/txs/chain`;
    if (updatedValues.cursorValue) {
      url = `${url}/${updatedValues.cursorValue}`;
    }
    const response = await axios.get(url);
    const newLitecoinspaceTransactions = response?.data;
    if (newLitecoinspaceTransactions === undefined || newLitecoinspaceTransactions.length === 0) {
      updatedValues.nextPageExists = false;
      return updatedValues;
    }

    updatedValues.cursorValue = newLitecoinspaceTransactions.slice(-1)?.[0]?.txid; // Oldest fetched tx id

    const accountTransactions = newLitecoinspaceTransactions
      .map(litecoinscanTx => transformToAccountTx(litecoinscanTx, address, accountId, exchangeRates, currency))
      .filter(accountTx => determineTxAcceptance(accountTx));
    let accountTransactionsWithRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, 'ltc');
    if (highRiskOnly) {
      accountTransactionsWithRisk = accountTransactionsWithRisk.filter(tx => isHighRisk(tx?.risk ?? 0));
    }

    updatedValues.txsBuffer = [...txsBuffer, ...accountTransactionsWithRisk];
    return updatedValues;
  };

  const getSupportedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, getSupportedFilters };
};
