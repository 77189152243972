import useTxsLoaderBase from './txsLoaderBase';
import { getBscChainConfig } from '../../chain-config/bsc-chain-config';
import useExternalApiKeys from '../externalApiKeys';

const useBscTxsLoader = ({ address, exchangeRates, currency, accountId, riskDetail, highRiskOnly }) => {
  const { externalApiKeys } = useExternalApiKeys();

  const bscChainConfig = getBscChainConfig({
    address,
    accountId,
    currency,
    highRiskOnly,
    riskDetail,
    externalApiKey: externalApiKeys.bscscan,
  });

  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: bscChainConfig,
  });

  return { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset };
};

export default useBscTxsLoader;
