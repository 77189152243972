import useTxsLoaderBase from './txsLoaderBase';
import { getDogeChainConfig } from '../../chain-config/doge-chain-config';
import useExternalApiKeys from '../externalApiKeys';

const useDogeTxsLoader = ({ address, exchangeRates, currency, riskDetail, accountId, highRiskOnly = false }) => {
  const { externalApiKeys } = useExternalApiKeys();

  const dogeChainConfig = getDogeChainConfig({
    address,
    accountId,
    currency,
    highRiskOnly,
    riskDetail,
    externalApiKey: externalApiKeys.tatum,
  });

  const { getNextTx, hasNextTx, getLastLoadedPage, getTotalPages, reset } = useTxsLoaderBase({
    exchangeRates,
    chainConfig: dogeChainConfig,
  });

  return { getNextTx, hasNextTx, reset, getLastLoadedPage, getTotalPages };
};

export default useDogeTxsLoader;
